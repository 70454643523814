<!-- 预警设置 -->

<template>
  <div class="setting">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <!-- <el-input v-model="codeValue" placeholder="请搜索商品编号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input> -->
        <el-input v-model="nameValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="serialId" label="商品编号"></el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" align="center" label="商品名称">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" align="center" label="规格">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" align="center" label="商品类目">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="enterBox" align="center" label="入箱数">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" align="center" label="品牌">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="validityPeriod" align="center" label="保质期(天)">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="inventoryCount" align="center" label="库存">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="warnCount" align="center" label="最小库存预警">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="warnPeriod" align="center" label="预警周期(天)">
          </el-table-column>
          <el-table-column label="操作" header-align="center" min-width="120" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleSet(scope.row)">设置</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>


    <!-- 弹窗 -->
    <el-dialog title="设置" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="最小库存预警" prop="minInventoryWarning">
              <el-input v-model="ruleForm.minInventoryWarning" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预警周期(天)" prop="warningCycle">
              <el-input v-model="ruleForm.warningCycle" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog" type="primary" plain>取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dayPass: false,
      productId: null,
      nameValue: '', // 搜索商品名称
      // codeValue: '', // 搜索商品编号
      barCode: '', // 搜索商品条形码
      // 表格数据
      tableData: [
        {
          productCode: 'sp001',
          productName: '苹果',
          specs: '500g',
          categoryName: '苹果',
          enterBox: '10',
          brand: '富士苹果',
          day: '10',
          small: '10',
          week: '10',
        }
      ],
      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        minInventoryWarning: '',
        warningCycle: '',
      },
      rules: {
        minInventoryWarning: [{ required: true, message: '请输入', trigger: 'blur' }, { pattern: /^[1-9]\d*$/, message: '不能输入数字以外的字符', trigger: 'blur' }],
        warningCycle: [{ required: true, message: '请输入', trigger: 'blur' }, { pattern: /^[1-9]\d*$/, message: '不能输入数字以外的字符', trigger: 'blur' }],
      },
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getWarningSettingList();
  },

  methods: {
    getWarningSettingList() {
      this.loading = true;
      this.$axios.get(this.$api.getWarningSettingList, {
        params: {
          productName: this.nameValue || null,  // 搜索商品名称
          // serialId: this.codeValue || null,  // 搜索商品编号
          barCode: this.barCode || null,  // 搜索商品编号
          shopId: this.$store.state.userInfo.shopId, //shopId
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code == 100) {

          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 设置
    handleSet(row) {
      this.dialogVisible = true;
      this.productId = row.productId;
      this.$set(this.ruleForm, 'warningCycle', row.warnPeriod)
      this.$set(this.ruleForm, 'minInventoryWarning', row.warnCount)
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
      this.productId = null;
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.put(this.$api.changeWarningSetting, {
            warnCount: this.ruleForm.minInventoryWarning, // 最小预警数量
            warnPeriod: this.ruleForm.warningCycle, // 预警周期
            shopId: this.$store.state.userInfo.shopId, //shopId
            productId: this.productId,
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('设置成功!');
              this.handleCloseDialog();
              this.getWarningSettingList();
            }
          })
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },

    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getWarningSettingList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getWarningSettingList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getWarningSettingList();
    },

  }

}



</script>

<style scoped lang="scss">
.setting {


  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 180px;
      margin-left: 10px;
    }
  }

  h2 {
    padding-left: 150px;
    margin: 20px 0;
  }

  .row-bottom {

    /deep/.el-input,
    /deep/.el-input__inner {
      width: 255px;
    }

    /deep/ .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 30px;
    }

    /deep/.el-input__inner {
      background: #f2f2f2;
      border-radius: 10px 0 0 10px;
      border: none;
    }

    /deep/ .el-input-group__append {
      background: #f2f2f2;
      border-radius: 0px 10px 10px 0;
      border: none;
      color: #000;
    }

    .left {
      color: #272727;
      font-size: 16px;
      margin-left: 20px;
    }
  }
}

/* input number类型取消增减箭头*/
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// 光标上移
/deep/.el-input__inner {
  line-height: 1px !important;
}

.day {

  /deep/.el-input,
  /deep/.el-input__inner {
    width: 200px;
  }

  /deep/ .el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }

  /deep/.el-input__inner {
    background: #f2f2f2;
    border-radius: 10px 0 0 10px;
    border: none;
  }

  /deep/ .el-input-group__append {
    background: #f2f2f2;
    border-radius: 0px 10px 10px 0;
    border: none;
    color: #000;
  }
}
</style>
